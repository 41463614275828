import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'digits'
})
export class DigitsPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    const result = value.replace(/[A-Za-z]/, '');
    return result;
  }
}
