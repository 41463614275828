import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-personal-data-request-screen',
  templateUrl: './personal-data-request-screen.component.html',
  styleUrls: ['./personal-data-request-screen.component.scss']
})
export class PersonalDataRequestScreenComponent implements OnInit {
    @Output() userContinue = new EventEmitter<any>();

    constructor() { }

    ngOnInit(): void {}

    userCanContinue(): void {
        this.userContinue.emit(true);
    }
}
