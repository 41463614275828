import {Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';

@Directive({
    selector: '[appCustomItemHoverColor]'
})
export class CustomItemHoverColorDirective implements OnChanges {
    private static counter = 0;

    @Input() appCustomItemHoverColor;

    private readonly nativeEl: HTMLElement;

    styleEl: HTMLStyleElement = document.createElement('style');

    uniqueAttr = `app-item-hover-color-${CustomItemHoverColorDirective.counter++}`;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
    ) {
        this.nativeEl = this.el.nativeElement;
        this.nativeEl.setAttribute(this.uniqueAttr, '');
        this.nativeEl.appendChild(this.styleEl);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateColor();
    }

    updateColor(): void {
        // update dynamic style with the uniqueAttr
        this.nativeEl.addEventListener('mouseover', () => {
            this.renderer.setStyle(
                this.nativeEl,
                'background-color',
                this.appCustomItemHoverColor
            );
        });

        this.nativeEl.addEventListener('mouseout', () => {
            this.renderer.setStyle(
                this.nativeEl,
                'background-color',
                'transparent'
            );
        });

        this.nativeEl.addEventListener('touchstart', () => {
            this.renderer.setStyle(
                this.nativeEl,
                'background-color',
                this.appCustomItemHoverColor
            );
        });

        this.nativeEl.addEventListener('touchend', () => {
            this.renderer.setStyle(
                this.nativeEl,
                'background-color',
                'transparent'
            );
        });

        this.styleEl.innerText = `
            [${this.uniqueAttr}]:hover {
                .material-symbols-rounded {
                    background-color: #FFFFFF50!important;
                    border-radius: 10px;
                }
            }
            `;
    }
}
