<div class="d-flex flex-column">
    <div class="d-flex flex-row align-items-center" *ngIf="enableLabel">
  <div class="d-flex" *ngIf="showIcon">
    <span class="material-symbols-rounded" *ngIf="iconType === 'MATERIAL_SYMBOL'">{{ icon }}</span>
    <img [src]="icon" alt="Banner icon" *ngIf="iconType === 'IMAGE'"/>
  </div>
  <p class="small-label pb-2">{{ labelText }}</p>
</div>
    <div class="d-flex flex-column">
  <mat-form-field floatLabel="never">
    <ng-container *ngIf="enableEnterEvent">
      <!-- [mask]="mask" -->
      <input matInput
        [id]="id"
        [formControl]="controlName"
        [placeholder]="placeholder"
        [type]="type"
        (keyup.enter)="onEnterEvent()"
        (focus)="onFocusedEvent()"
        (focusout)="onFocusedOutEvent()"/>
    </ng-container>
    <ng-container *ngIf="!enableEnterEvent">
      <input matInput
        formControlName="{{ controlName }}"
        [placeholder]="placeholder"
        [type]="type"
        (focus)="onFocusedEvent()"
        (focusout)="onFocusedOutEvent()"/>
    </ng-container>
  </mat-form-field>
</div>
</div>


