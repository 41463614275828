import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'orderFirstName'
})
export class OrderFirstNamePipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
      const datas = value.split(',');
      return datas[0];
  }
}
