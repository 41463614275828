import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {HttpClient, HttpStatusCode} from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SocialUser } from 'angularx-social-login';
import { ResponseApi } from './shared/models/data/response';
import { LoginModel } from './public/login/login.model';
import { environment } from 'src/environments/environment';
import {encrypt, getAccount} from './consts/utilities';
import {emailRegex} from './consts/regex-patterns.consts';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private readonly BASE_URL: string;

    constructor(
        private http: HttpClient,
        private router: Router,
        private dialog: MatDialog,
    ) {
        this.BASE_URL = `${environment.serverURL}/auth`;
    }

    login(loginData: LoginModel): Observable<any> {
        localStorage.removeItem('company-data');

        const params = {
            account: loginData.account,
            password: encrypt(loginData.password),
            pass_type: loginData.pass_type
        };

        return this.http.post<any>(`${this.BASE_URL}/sign-in`, params);
    }

    createLink(payload: any): Observable<any> {
        const account = getAccount();

        const data = {
            email: account,
            password: encrypt(payload.password),
            usuario: payload.usuario,
            tipo_login: payload.tipo_login,
            nombre: payload.nombre,
            ap_paterno: payload.pap_paterno,
            ap_materno: payload.pap_materno
        };

        return this.http.post(`${this.BASE_URL}/link-new-account`, data);
    }

    validateVerificationCode(userId: number, account: string, code: string) {
        const params = {
            userId,
            account,
            code,
            method: account.match(emailRegex) ? '1' : '2',
        };

        return this.http.post<any>(`${environment.serverURL}/verification/code-confirmation`, params);
    }

    validateSignUpCode(
        userId: number,
        account: string,
        code: string
    ): Observable<any> {
        const method = (emailRegex.test(account) ? '1' : '2');
        const params = {
            userId,
            account,
            code,
            method,
        };

        return this.http.post<any>(`${environment.serverURL}/verification/confirm-signup-code`, params);
    }

    signUp(params: any): Observable<any> {
        const payload = {
            account: params.account,
            password: encrypt(params.password),
            channel: params.channel,
        };

        return this.http.post<ResponseApi<any>>(`${ this.BASE_URL }/sign-up`, payload);
    }

    public invalidateSession() {
        return this.http.get(`${ this.BASE_URL }/invalidate-session`, {}).subscribe(
            () => {
                localStorage.removeItem('company-data');
                localStorage.removeItem('user_id');
                localStorage.removeItem('user_account');
                localStorage.setItem('cw_sess_st', 'invalid');
                this.router.navigate(['/account']);
            },
            (error) => {
                console.log(error);
            }
        );
    }

    public logOut() {
        // Change action to PUT because this action no require response content
        this.http.get(`${ this.BASE_URL }/logout`, {}).subscribe(
            _ => {
                sessionStorage.clear();
                localStorage.removeItem('user_enterprise_id');
                localStorage.removeItem('user_multi_enterprise');
                localStorage.removeItem('user_id');
                localStorage.removeItem('user_account');
                localStorage.removeItem('userType');
                localStorage.removeItem('company-data');
                localStorage.removeItem('require_pwd_in_link');
                localStorage.setItem('cw_sess_st', 'closed');
                this.dialog.closeAll();
                this.router.navigate(['/account']);
            }
        );
    }

    changePassword(account: string, password: string): Observable<any> {
        const params = {
            account,
            password: encrypt(password),
        };

        return this.http.post<ResponseApi<any>>(`${ this.BASE_URL }/reset-password`, params);
    }

    loginWithGoogle(socialUser: SocialUser): Observable<any> {
        localStorage.removeItem('company-data');

        const payload = {
            account: socialUser.email,
            password: socialUser.idToken,
            pass_type: 1,
            provider: 'GOOGLE'
        };

        return this.http.post<any>(`${ this.BASE_URL }/sign-in`, payload);
    }

    loginWithApple(socialUser: SocialUser | any): Observable<any> {
        const payload = {
            account: socialUser.user.email,
            password: socialUser.authorization.code,
            pass_type: 3,
            provider: 'APPLE'
        };

        localStorage.removeItem('company-data');

        return this.http.post<any>(`${ this.BASE_URL }/sign-in`, payload);
    }

    public checkSession(): Observable<any> {
        return this.http.get(`${this.BASE_URL}/check-session`);
    }

    public get loggedIn(): boolean {
        return (localStorage.getItem('cw_sess_st') || 'invalid') === 'ok';
    }
}
