import {Component, Input, OnInit} from '@angular/core';
import {TextStyle} from '../h1/h1.component';

@Component({
    selector: 'app-h2',
    templateUrl: './h2.component.html',
    styleUrls: ['./h2.component.scss']
})
export class H2Component implements OnInit {

    @Input() textStyle = TextStyle.TitleCase;
    @Input() text = '';

    constructor() {
    }

    ngOnInit(): void {
    }

}
