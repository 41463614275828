import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-timeout',
    templateUrl: './timeout.component.html',
    styleUrls: ['./timeout.component.scss']
})
export class TimeoutComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<TimeoutComponent>) {}

    ngOnInit() {}
}
1;
