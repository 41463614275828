<div>
    <div class="template template-wide" *ngIf="templateType === 'wide'" #template>
      <div class="template-left-area">
        <div class="template-icon-area" *ngIf="showIcon">
          <span class="material-symbols-rounded" #templateIcon>{{ icon }}</span>
        </div>
        <div class="template-icon-area" *ngIf="showImageForMobile && isMobile">
            <img [src]="imagePath" width="48" height="48">
        </div>
        <div class="template-text-area">
          <p class="template-pill" #templatePill *ngIf="showPill">
            {{ pillText }}
          </p>
          <p class="template-text-title" #templateTitle>{{ title }}</p>
          <p class="template-text-description" #templateDesc>{{ description }}</p>
        </div>
      </div>
      <div class="template-right-area">
          <div class="template-img-area" [hidden]="isMobile || !showImage">
              <img [src]="imagePath" width="128" height="128">
          </div>
         <div class="template-action-area" *ngIf="showActions">
          <button mat-raised-button
            class="role-action-btn">
              {{ actionButtonText }}
          </button>
        </div>
      </div>
    </div>

  <ng-container *ngIf="templateType === 'extended'">
    <div class="template template-extended" #template>
      <div class="template-text-area">
        <p class="template-text-title">{{ title }}</p>
        <p class="template-text-description">{{ description }}</p>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="templateType === 'wide_with_options'">
    <div class="template template-with-options" #template>
    </div>
  </ng-container>
</div>
