import {
    BrowserModule,
    Title,
} from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA, InjectionToken, LOCALE_ID} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AuthGuardService } from './auth-guard.service';
import { JwtModule } from '@auth0/angular-jwt';

import {DatePipe} from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { HttpInterceptorService } from './http-interceptor.service';
import { PlatformModule } from '@angular/cdk/platform';

import { MAT_DATE_LOCALE } from '@angular/material/core';

import { SubmitFormDirective } from './submit-form.directive';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { SignoutNotificationComponent } from './signout-notification/signout-notification.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { RoleDirective } from './role.directive';
import { SessionExpiredComponent } from './session-expired/session-expired.component';

export function tokenGetter() {
    return sessionStorage.getItem('access_token');
}
import { TimeoutComponent } from './timeout/timeout.component';

// import { AngularResizedEventModule } from 'angular-resize-event';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';

import { ConfirmationDialogComponent } from './dashboard-module/utils/confirmation-dialog/confirmation-dialog.component';
import { SharedComponentsModule } from './shared-components/shared-components.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { VersionComponent } from './version/version.component';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from './dashboard-module/utils/shared.module';

import { SearchFilterByPipe } from './search-filter-by.pipe';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { ValidatorsService } from './services/validators.service';
import { LinkCardService } from './link.card.service';
import { StorageService } from './storage.service';
import { LinkFixerPipe } from './link-fixer.pipe';
import { QRCodeModule } from 'angular2-qrcode';
import { AppleLoginProvider } from './shared/services/sign-in-with-apple.service';

import {MenuStateService} from './dashboard/menu-state.service';

import { ModalDeleteComponent } from './modal-delete/modal-delete.component';
import { NgToggleModule } from 'ng-toggle-button';

import {AnalyticsReporterService} from './services/analytics-reporter.service';
import {RemoteConfigService} from './services/remote-config.service';
import {DashboardModule} from './dashboard-module/dashboard.module';
import { LayoutComponent } from './layout/layout.component';
import { NavigationComponent } from './layout/navigation/navigation.component';
import {TimeoutInterceptorService} from './timeout-interceptor.service';
import { MenuItemDirective } from './menu-item.directive';
import { BottomMenuComponent } from './layout/bottom-menu/bottom-menu.component';
import {MaterialModule} from './material.module';
import {PoliciesModule} from './policies/policies.module';
import {ProfileModule} from './profile/profile.module';

@NgModule({
    declarations: [
        ConfirmationDialogComponent,
        AppComponent,
        SubmitFormDirective,
        SanitizeHtmlPipe,
        SignoutNotificationComponent,
        RoleDirective,
        SessionExpiredComponent,
        TimeoutComponent,
        VersionComponent,
        SearchFilterByPipe,
        LinkFixerPipe,
        ModalDeleteComponent,
        LayoutComponent,
        NavigationComponent,
        MenuItemDirective,
        BottomMenuComponent,
    ],
    imports: [
        MaterialModule,
        QRCodeModule,
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        SharedModule,
        NgxMaskModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        LayoutModule,
        NgxMatSelectSearchModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter
            }
        }),
        CdkStepperModule,
        PlatformModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production
        }),
        SocialLoginModule,
        SharedComponentsModule,
        NgToggleModule,
        NgxSpinnerModule,
        PoliciesModule,
        DashboardModule,
        ProfileModule,
    ],
    providers: [
        AuthGuardService,
        LinkCardService,
        DatePipe,
        // { provide: LOCALE_ID, useValue: 'es-ES' },
        {provide: MAT_DATE_LOCALE, useValue: 'es-MX'},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TimeoutInterceptorService,
            multi: true
        },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: true,
                providers: [
                    {
                        id: AppleLoginProvider.PROVIDER_ID,
                        provider: new AppleLoginProvider(
                            'com.app.calimax'
                        ),
                    },
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            '1059682028519-asv56kupoj8pmdrpk76e1u7ajsnbsv5s.apps.googleusercontent.com'
                        )
                    },
                    {
                        // Prod ID: 463585114227009
                        // Dev ID: 1701763366904984
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider('463585114227009')
                    }
                ],
            } as SocialAuthServiceConfig
        },
        StorageService,
        ValidatorsService,
        SearchFilterByPipe,
        MenuStateService,
        AnalyticsReporterService,
        RemoteConfigService,
        Title,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {}
