import { Pipe, PipeTransform } from '@angular/core';
// import { last } from 'rxjs/operators';

@Pipe({
    name: 'cardNumber'
})
export class CardNumberPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        const data: string = value.toString();

        if (data.length < 16) {
            console.warn('Invalid Card Number. Aborting Data Formatting');
            return value;
        }

        try {
            const firstGroup = data.substring(0, 4);
            const secondGroup = data.substring(4, 8);
            const thirdGroup = data.substring(8, 12);
            const fourGroup = data.substring(12, 16);

            return `${firstGroup} ${secondGroup} ${thirdGroup} ${fourGroup}`;
        } catch (e) {
            return value;
        }
    }
}
