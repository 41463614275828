<div
    style="
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px;

        margin: 5px;
    "
>
    <input
        #firstDigit
        (keydown)="firstDigitFn($event)"
        (paste)="onPaste($event)"
        maxlength="1"
        class="code-input"
        *ngIf="stringSize >= 1"
    />
    <input
        #secondDigit
        (keydown)="secondDigitFn($event)"
        (paste)="onPaste($event)"
        maxlength="1"
        class="code-input"
        *ngIf="stringSize >= 2"
    />
    <input
        #thirdDigit
        (keydown)="thirdDigitFn($event)"
        (paste)="onPaste($event)"
        maxlength="1"
        class="code-input"
        *ngIf="stringSize >= 3"
    />
    <input
        #fourthDigit
        (keydown)="fourthDigitFn($event)"
        (paste)="onPaste($event)"
        maxlength="1"
        class="code-input"
        *ngIf="stringSize >= 4"
    />
    <input
        #fifthDigit
        (keydown)="fifthDigitFn($event)"
        (paste)="onPaste($event)"
        maxlength="1"
        class="code-input"
        *ngIf="stringSize >= 5"
    />
    <input
        #sixthDigit
        (keydown)="sixthDigitFn($event)"
        (paste)="onPaste($event)"
        maxlength="1"
        class="code-input"
        *ngIf="stringSize >= 6"
    />
    
</div>
