<h1 *ngIf="textStyle === 'tt'">
    {{ text | titlecase }}
</h1>

<h1 *ngIf="textStyle === 'ttb'">
    <strong>{{ text | titlecase }}</strong>
</h1>

<h1 *ngIf="textStyle === 'u'">
    {{ text | uppercase }}
</h1>

<h1 *ngIf="textStyle === 'b'">
    <strong>{{ text }}</strong>
</h1>

<h1 *ngIf="textStyle === 'ub'">
    <strong>{{ text | uppercase }}</strong>
</h1>