<ng-container [formGroup]="controlContainer.control">
    <div class="d-flex flex-column">
        <mat-form-field floatLabel="never">
            <div matPrefix class="d-flex flex-row align-items-center gap-3">
                <span [hidden]="accentColorForIcon"
                      [style.color]="iconColor"
                      class="material-symbols-rounded ms-2">
                    {{
                        forPhone && forEmail
                            ? 'account_circle'
                            : forPhone ? 'phone' : 'email'
                    }}
                </span>
                <span *ngIf="accentColorForIcon"
                      class="material-symbols-rounded accent-color ms-2">
                    {{
                    forPhone && forEmail
                        ? 'account_circle'
                        : forPhone ? 'phone' : 'email'
                    }}
                </span>
                <p *ngIf="channel === 'sms' || channel === 'wa' || forPhone && !forEmail"
                        tabindex="-1"
                        class="p-0 medium-text bg-transparent"
                        [ngStyle]="{ 'font-size': '18px' }">
                    +{{ controlContainer.value.pais }}
                </p>
            </div>
            <div class="d-flex">
                <!-- [mask]="channel === 'email' ? '' : '(000) 000-0000'" -->
                <input matInput
                       id="input-account"
                       [pattern]="currentPattern"
                       [placeholder]="placeholder
                            ? placeholder
                            : forEmail && forPhone
                                ? 'Ingresa tu teléfono o correo'
                                : forEmail && !forPhone
                                    ? 'Ingresa tu correo electronico'
                                    : 'Ingresa tu num. de teléfono'
                        "
                       formControlName="{{ controlName }}"/>
            </div>
        </mat-form-field>
        <div *ngIf="
            controlContainer.control.get('account').hasError('required')
            && controlContainer.control.get('account').dirty">
            <div class="d-flex flex-row align-items-center gap-3">
                <img src="../../../assets/img/error-circle-100.svg"
                     width="32"
                     height="32"
                     alt="Account Error">
                <p class="text-danger m-0 py-2">Ingresa un número de teléfono o correo.</p>
            </div>
        </div>
        <div *ngIf="
            controlContainer.control.hasError('notFoundedCC')">
            <div class="d-flex flex-row align-items-center gap-3">
                <img src="../../../assets/img/error-circle-100.svg"
                     width="32"
                     height="32"
                     alt="Account Error">
                <p class="text-danger m-0 py-2">El numero de teléfono es invalido o no se encontró lada.</p>
            </div>
        </div>
        <div *ngIf="controlContainer.control.get('account').hasError('invalidEmail')
                    && controlContainer.control.get('account').dirty">
            <div class="d-flex flex-row align-items-center gap-3">
                <img src="../../../assets/img/error-circle-100.svg"
                     width="32"
                     height="32"
                     alt="Account Error">
                <p class="text-danger m-0 py-2">Ingresa un correo electrónico valido.</p>
            </div>
        </div>
        <div *ngIf="controlContainer.control.get('account').hasError('invalidPhone')
                    && controlContainer.control.get('account').dirty">
            <div class="d-flex flex-row align-items-center gap-3">
                <img src="../../../assets/img/error-circle-100.svg"
                     width="32"
                     height="32"
                     alt="Account Error">
                <p class="text-danger m-0 py-2">Ingresa un número de teléfono valido. (No se permiten simbolos ni espacios).</p>
            </div>
        </div>
    </div>
<!--    <ng-template #countriesMenu>-->
<!--        <div class="p-3">-->
<!--            <p class="x-small-text text-uppercase spaced-label pb-3">Selecciona pais</p>-->
<!--            <button class="border-0 w-100" mat-menu-item-->
<!--                    (click)="selectCountryCode('52')">-->
<!--                <span class="material-symbols-rounded">language</span>-->
<!--                +52-->
<!--            </button>-->
<!--            <button class="border-0 w-100" mat-menu-item-->
<!--                    (click)="selectCountryCode('1')">-->
<!--                <span class="material-symbols-rounded">language</span>-->
<!--                +1-->
<!--            </button>-->
<!--        </div>-->
<!--        <mat-divider></mat-divider>-->
<!--        <button mat-flat-button-->
<!--                class="bg-transparent accent-color w-100"-->
<!--                (click)="dismissMenu()">-->
<!--            Aceptar-->
<!--        </button>-->
<!--    </ng-template>-->
</ng-container>
