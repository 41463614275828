<div class="w-100 scan-and-go-presenter container">
    <div class="scan-and-go-presenter-top-area">
    </div>
    <div class="scan-and-go-presenter-middle-area">
        <img src="../../../assets/img/caliwallet_scan_logo.svg"
             class="mb-4"
             width="84"
             height="84"
             alt>
        <h3 class="sg-present-title gradient-accent-color text-center">
            <strong>Necesitamos algunos datos</strong>
        </h3>
        <div class="scan-and-go-terms">
            <p class="small-label">
                Al presionar el botón continuar se mostrara una pantalla donde deberas
                ingresar tu nombre completo <strong class="small-text"> para utilizar Scan & Go</strong> de
                <strong class="small-text">CaliWallet</strong>.
            </p>
        </div>
    </div>
    <div class="scan-and-go-presenter-bottom-area">
        <button mat-flat-button
            class="role-action-btn w-100"
            (click)="userCanContinue()">
            Continuar
        </button>
    </div>
</div>
