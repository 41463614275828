import {Component, ElementRef, EventEmitter, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, FormGroupDirective, NgForm, ValidationErrors, Validators} from '@angular/forms';
import {AuthService} from '../../auth.service';
import {ErrorStateMatcher} from '@angular/material/core';
import {environment} from '../../../environments/environment';
import {SmsVerificationComponent} from '../sms-verification/sms-verification.component';
import {SignUp} from './signup.model';
import {emailRegex, phoneRegex} from '../../consts/regex-patterns.consts';
import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {mobile} from '../../consts/utilities';

export class EmailErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(
        control: FormControl | null,
        form: FormGroupDirective | NgForm | null
    ): boolean {
        return control.dirty && form.invalid;
    }
}

export enum Channel {
    SMS = 'sms',
    Email = 'email',
    WhatsApp = 'wa',
}

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})

export class SignupComponent implements OnInit {
    version = environment.version;

    matcher = new EmailErrorStateMatcher();
    password = new FormControl('', Validators.required);
    passConfirmation = new FormControl('', Validators.required);

    public waitingActivation = false;

    @Output() goToLogin = new EventEmitter<number>();
    // tslint:disable-next-line: no-output-on-prefix
    @Output() onUserActivation = new EventEmitter<any>();

    @ViewChild('accountInput')
    accountInput: ElementRef<HTMLInputElement>;

    @ViewChild('passwordInput')
    passwordInput: ElementRef<HTMLInputElement>;

    @ViewChild('CodeVerification', { static: true })
    smsVerification: SmsVerificationComponent;

    // countryCode = '52';
    signUpForm = new FormGroup(
        {
            pais: new FormControl('52', [Validators.nullValidator]),
            account: new FormControl('', [
                Validators.required,
                this.accountValidator.bind(this)
            ]),
            password: new FormControl('', [
                Validators.required,
                Validators.minLength(8),
                Validators.maxLength(24)
            ]),
            passwordConfirmation: new FormControl('', [
                Validators.required,
                Validators.minLength(8),
                Validators.maxLength(24)
            ]),
        },
        {
            validators: this.passwordValidator,
        }
    );

    channel: Channel;

    // Referencia a Dialog
    @ViewChild('OkResponse', { static: true })
    OkResponseTemplateRef: TemplateRef<any>;

    @ViewChild('confirmationCode', { static: true })
    confirmationCodeTempl: TemplateRef<any>;

    @ViewChild('errorMessageTempl', { static: true })
    messageTempl: TemplateRef<any>;

    public hidePass = true;

    public errorMessage = '';

    constructor(
        private authService: AuthService,
        private dialog: MatDialog,
        private bottom: MatBottomSheet,
    ) {}

    ngOnInit() {
        this.observeAccountInput();
    }

    private observeAccountInput(): void {
        this.signUpForm.get('account').valueChanges.subscribe(
            newValue => {
                const trimmedValue = newValue.trim();

                if (phoneRegex.test(trimmedValue)) {
                    this.channel = Channel.WhatsApp;
                }

                if (emailRegex.test(trimmedValue)) {
                    this.channel = Channel.Email;
                }
            }
        );
    }

    passwordValidator(form: FormGroup) {
        const condition = form.get('password').value !== form.get('passwordConfirmation').value;
        return condition ? { passwordDoCheck: true } : null;
    }

    private notifyUserCodeSendProcess(onClosed: () => void): void {
        if (this.channel === Channel.Email) {
            onClosed();
            return;
        }

        let notification: MatDialogRef<any> | MatBottomSheetRef;

        if (mobile()) {
            notification = this.bottom.open(this.confirmationCodeTempl);
            notification.afterDismissed().subscribe(() => onClosed());
        }

        if (!mobile()) {
            notification = this.dialog.open(this.confirmationCodeTempl);
            notification.afterClosed().subscribe(() => onClosed());
        }
    }

    public changeCodeChannelToSMS(): void {
        this.channel = Channel.SMS;
        this.dismissNotifications();
    }

    public dismissNotifications(): void {
        if (mobile()) {
            this.bottom.dismiss();
        }

        if (!mobile()) {
            this.dialog.closeAll();
        }
    }

    public register(): void {
        if (this.signUpForm.invalid) {
            this.errorMessage = 'Por favor, completa la información para registrarte.';
            this.openErrorNotification();
            return;
        }

        this.signUp();
    }

    private buildRegisterPayload(): SignUp {
        let account = this.signUpForm.get('account').value.trim();
        const password = this.signUpForm.get('password').value;

        if (this.channel === Channel.WhatsApp || this.channel === Channel.SMS) {
            const countryCode = this.signUpForm.get('pais').value;
            account = `${countryCode}${account}`;
        }

        return {
            account,
            password,
            channel: this.channel
        };
    }

    private accountValidator(control: AbstractControl): ValidationErrors | null {
        const trimmedValue = control.value.trim();

        if (this.channel === Channel.WhatsApp && !phoneRegex.test(trimmedValue)) {
            return { invalidPhone: true };
        }

        if (this.channel === Channel.Email && !emailRegex.test(trimmedValue)) {
            return { invalidEmail: true };
        }

        return null;
    }

    private signUp() {
        this.notifyUserCodeSendProcess(() => {
            const signUpData: SignUp = this.buildRegisterPayload();

            this.authService.signUp(signUpData).subscribe(
                (res) => {
                    const { account } = signUpData;
                    this.smsVerification.open({
                        id: res.data.id_usu,
                        account: account.trim(),
                        channel: this.channel,
                        state: 'sendingCode',
                        typeOfVerification: 'signup',
                        isForReset: false
                    });
                    this.waitingActivation = true;
                },
                (err: HttpErrorResponse) => {
                    const { status } = err;

                    if (status === HttpStatusCode.Conflict) {
                        this.signUpForm.setErrors({ existingAccount: true });
                    }
                }
            );
        });
    }

    private openErrorNotification(): void {
        if (mobile()) {
            this.bottom.open(this.messageTempl);
        }

        if (!mobile()) {
            this.dialog.open(this.messageTempl);
        }
    }

    moveFocus($event) {
        const key = $event.which || $event.keyCode;
        if (key === 13) {
            if ($event.target.id === 'input-password') {
                const inputEmailConfirmation = document.getElementById(
                    'input-pass-confirmation'
                );
                inputEmailConfirmation.focus();
            } else if ($event.target.id === 'input-password-confirmation') {
                const inputPassword = document.getElementById('input-password');
                inputPassword.focus();
            }
        }
    }

    getTabIndex(tabIndex: number): void {
        this.waitingActivation = false;
        this.signUpForm.reset();
    }
}
