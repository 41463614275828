<div class="version-dialog-container">
    <img src="assets/img/calimax-logo.svg" width="128" height="64" alt="Calimax Logo" />
<!-- <h1 mat-dialog-title>Acerca de</h1> -->
    <div mat-dialog-content class="version-content">
        <h5>CaliWallet de Calimax</h5>
        <p>Versión <strong>{{ version }}</strong></p>
    </div>
    <div class="dialog-actions">
        <button mat-dialog-close mat-raised-button>
            Cerrar
        </button>
    </div>
</div>


