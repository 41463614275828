import {Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {ControlContainer} from '@angular/forms';
import {Channel} from '../../public/signup/signup.component';
import {MatDialog} from '@angular/material/dialog';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {mobile} from '../../consts/utilities';
import {emailRegex, phoneRegex} from '../../consts/regex-patterns.consts';
import {McTelephoneService} from '../../mc-telephone.service';
import {HttpStatusCode} from '@angular/common/http';

@Component({
  selector: 'app-auth-input',
  templateUrl: './auth-input.component.html',
  styleUrls: ['./auth-input.component.scss']
})
export class AuthInputComponent implements OnInit {

    @Input() controlName: string;
    @Input() placeholder: string;
    @Input() showIcon = false;
    @Input() iconColor = '#000000';
    @Input() accentColorForIcon = false;
    @Input() enableLabel = false;
    @Input() enableEnterEvent: boolean;
    @Input() viewElementRef: ElementRef<any>;
    @Input() currentPattern: string | RegExp = '';
    @Input() forEmail = true;
    @Input() forPhone = true;

    channel: Channel;
    account = '';

    // Events
    @Output() enter = new EventEmitter<boolean>();

    constructor(
        private dialog: MatDialog,
        private bottomSheet: MatBottomSheet,
        private mcTelephoneService: McTelephoneService,
        public controlContainer: ControlContainer
    ) { }

    ngOnInit(): void {
        this.observeAccountPattern();
        // this.controlContainer.control.addAsyncValidators([
        //     this.validatorService.getCountryCode
        // ]);
        // this.controlContainer.control.updateValueAndValidity();
    }

    onEnterEvent(): void {
        this.enter.emit();
    }

    private observeAccountPattern(): void {
        this.controlContainer.control.get(this.controlName).valueChanges.subscribe(
            newValue => {
                const trimmedValue = newValue.trim();

                if (trimmedValue === '') {
                    this.channel = null;
                    this.controlContainer.control.get(this.controlName)
                        .setErrors({ required: true });
                    return;
                }

                if (!this.forEmail || !this.forPhone) {
                    if (this.forEmail && !this.forPhone) {
                        this.channel = Channel.Email;

                       if (!emailRegex.test(trimmedValue)) {
                           this.controlContainer.control.get(this.controlName).setErrors(
                               { invalidEmail: true }
                           );
                       }
                    }

                    if (!this.forEmail && this.forPhone) {
                        this.channel = Channel.WhatsApp;

                        if (!phoneRegex.test(trimmedValue)) {
                            this.controlContainer.control.get(this.controlName).setErrors(
                                { invalidPhone: true }
                            );
                        }
                    }

                    return;
                }

                this.channel = (phoneRegex.test(trimmedValue))
                    ? Channel.WhatsApp
                    : emailRegex.test(trimmedValue) ? Channel.Email : null;

                if (this.channel === Channel.WhatsApp) {
                   this.detectCountryCodeByPhone(trimmedValue);
                }

                if (this.channel === Channel.Email || this.channel === Channel.WhatsApp) {
                    return;
                }

                this.controlContainer.control.get(this.controlName).setErrors(
                    { invalidData: true }
                );
            }
        );
    }

    private detectCountryCodeByPhone(phone: string): any {
        this.mcTelephoneService.detectCountryCode(phone).subscribe(
            (response) => {
                const { statusCode, data } = response;

                if (statusCode === HttpStatusCode.Ok) {
                    const { CountryCode: countryCode } = data;

                    if (countryCode > 0) {
                        if (this.controlContainer.value.pais) {
                            this.controlContainer.control.get('pais').setValue(countryCode);
                        }
                    }

                    if (countryCode === 0) {
                        this.controlContainer.control.setErrors({ notFoundedCC: true });
                    }
                }
            },
            error => {
                this.controlContainer.control.setErrors({ unableToCheckAccount: true });
            },
        );
    }

    // public openCountriesMenu(): void {
    //     const isMobile = mobile();
    //
    //     if (isMobile) {
    //         this.bottomSheet.open(this.countriesMenu);
    //     }
    //
    //     if (!isMobile) {
    //         this.dialog.open(this.countriesMenu);
    //     }
    // }

    public dismissMenu(): void {
        const isMobile = mobile();

        if (isMobile) {
            this.bottomSheet.dismiss();
        }

        if (!isMobile) {
            this.dialog.closeAll();
        }
    }
}
