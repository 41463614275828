import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2} from '@angular/core';
import {AppUtils} from '../../app-utils';
import {Channel} from '../../public/signup/signup.component';
import {FormControl} from '@angular/forms';
import {emailRegex, phoneRegex} from '../../consts/regex-patterns.consts';

@Component({
  selector: 'app-auth-input-nfr',
  templateUrl: './auth-input-nfr.component.html',
  styleUrls: ['./auth-input-nfr.component.scss']
})
export class AuthInputNfrComponent implements OnInit {

    @Input() control: FormControl;
    @Input() placeholder: string;
    @Input() showIcon = false;
    @Input() iconColor = '#000000';
    @Input() enableLabel = false;
    @Input() enableEnterEvent: boolean;
    @Input() viewElementRef: ElementRef<any>;

    channel: Channel;
    countryCode = '52';
    account = '';

    // Events
    @Output() enter = new EventEmitter<boolean>();

    constructor(
        private renderer: Renderer2,
    ) { }

    ngOnInit(): void {
        this.observeAccountPattern();
    }

    onFocusedEvent(): void {
        if (AppUtils.mobile()) {
            this.renderer.addClass(this.viewElementRef.nativeElement, 'fit-height');
        }
    }

    onFocusedOutEvent(): void {
        if (AppUtils.mobile()) {
            this.renderer.removeClass(this.viewElementRef.nativeElement, 'fit-height');
        }
    }

    onEnterEvent(): void {
        this.enter.emit();
    }

    selectCountryCode(code: string): void {
        this.countryCode = code;
    }

    private observeAccountPattern(): void {
        this.control.valueChanges.subscribe(
            newValue => {

                if (newValue === '') {
                    this.channel = null;
                    this.control.setErrors({ required: true });
                    return;
                }

                if (phoneRegex.test(newValue)) {
                    this.channel = Channel.SMS;
                }

                if (emailRegex.test(newValue)) {
                    this.channel = Channel.SMS;
                }
            }
        );
    }
}
