<div class="w-100 payment-required-container container">
    <div class="payment-required-header">

    </div>
    <div class="payment-required-content">
        <img src="../../../assets/img/caliwallet_scan_logo.svg"
             class="mb-4"
             width="84"
             height="84"
             alt>
        <h3 class="sg-present-title gradient-accent-color text-center">
            <strong>Agrega un metodo de pago</strong>
        </h3>
        <div class="shopp-terms">
            <p class="small-label">
                Al presionar el botón continuar se mostrara una pantalla donde deberas
                ingresar un metodo de pago <strong class="small-text"> para utilizar Scan & Go</strong> de
                <strong class="small-text">CaliWallet</strong>.
            </p>
        </div>
    </div>
    <div class="payment-required-bottom">
        <button mat-flat-button
            class="role-action-btn w-100"
            (click)="userCanContinue()">
            Continuar
        </button>
    </div>
</div>
