import {Component, Input, OnInit} from '@angular/core';


export enum TextStyle {
    TitleCase = 'tt',
    TitleCaseBold = 'ttb',
    Bold = 'b',
    Uppercase = 'u',
    UppercaseBold = 'ub'
}

@Component({
    selector: 'app-h1',
    templateUrl: './h1.component.html',
    styleUrls: ['./h1.component.scss']
})
export class H1Component implements OnInit {

    @Input() textStyle = TextStyle.TitleCase;
    @Input() text = '';

    constructor() {
    }

    ngOnInit(): void {
    }

}
