import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'multipleCapitalize'
})
export class MultipleCapitalizePipe implements PipeTransform {

  transform(value: string, args?: any): string {
    let result: string;

    if (value) {
      const words: string[] = value.split(' ');

      if (words.length > 1) {
        const newString: string[] = [];

        words.forEach(word => {
            const capital = word.substring(0, 1);
            const remains = word.substring(1, word.length - 1).toLowerCase();
            const new_word = `${capital}${remains}`;

            newString.push(`${new_word} `);
        });

        result = newString.toString()
          .replace(',', ' ')
          .replace(' ,', ' ');

      } else {
        const capital = value.substring(0, 1);
        const remains = value.substring(1, value.length).toLowerCase();
        result = `${capital}${remains}`;
      }
    } else {
      result = '';
    }

    return result;
  }
}
