import { Directive, Input, ElementRef, OnInit } from '@angular/core';

@Directive({
    selector: '[appRoleElements]'
})
export class RoleElementsDirective implements OnInit {
    @Input('appRoleElements') private rola: string;
    public roles: any[];
    constructor(private elementRef: ElementRef) {}
    ngOnInit() {
        // let itsInRoles = false;
        this.roles = JSON.parse(window.sessionStorage.getItem('rutas'));
        this.roles.map(v => {
            if (v.ruta === this.rola) {
                this.elementRef.nativeElement.style.display = 'none';
            }
        });
    }
}
