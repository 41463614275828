<div class="d-flex flex-column">
    <mat-form-field floatLabel="never">
        <span matPrefix
            [style.color]="iconColor"
            class="material-symbols-rounded ms-2">
            account_circle
        </span>
        <div class="d-flex">
            <button mat-flat-button
                *ngIf="channel === 'sms'"
                [autofocus]="false"
                tabindex="-1"
                class="p-0 medium-text bg-transparent"
                [matMenuTriggerFor]="countryMenu"
                [ngStyle]="{ 'width': '20%' }">
                <strong>+{{ countryCode }}</strong>
            </button>
            <!-- [mask]="channel === 'email' ? '' : '(000) 000-0000'" -->
            <input matInput
               [ngStyle]="{ 'width': (channel === 'email') ? '100%' : '80%' }"
               id="input-account"
               placeholder="Teléfono o correo"
               [formControl]="control" />
        </div>
        <mat-error *ngIf="control.get('account').hasError('required')">
            Debes ingresar un numero de teléfono o correo
        </mat-error>
        <mat-error *ngIf="control.get('account').hasError('invalidEmail')">
            Escribe un correo electrónico valido
        </mat-error>
        <mat-error *ngIf="control.get('account').hasError('invalidPhone')">
            Escribe un numero de teléfono valido
        </mat-error>
    </mat-form-field>
</div>
<mat-menu #countryMenu="matMenu">
    <button class="mat-menu-bold" mat-menu-item
        (click)="selectCountryCode('52')">
        Mexico
    </button>
    <button class="mat-menu-bold" mat-menu-item
        (click)="selectCountryCode('1')">
        Estados Unidos
    </button>
</mat-menu>
