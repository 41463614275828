import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogInterface {
    title: string;
    content: string;
    buttonOk: string;
    buttonCancel: string;
}

@Component({
    selector: 'app-confirmation-dialog',
    template: `
        <h1 mat-dialog-title [innerHTML]="dialogObject.title"></h1>
        <div mat-dialog-content [innerHTML]="dialogObject.content"></div>
        <div mat-dialog-actions align="end">
            <button
                mat-flat-button
                color="primary"
                *ngIf="okBtnIsVisible()"
                [innerHTML]="dialogObject.buttonOk"
                (click)="okBtn()"
            ></button>
            <button
                *ngIf="cancelBtnIsVisible()"
                mat-flat-button
                [innerHTML]="dialogObject.buttonCancel"
                (click)="cancelBtn()"
            ></button>
        </div>
    `,
    styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
    public dialogObject: DialogInterface = {
        buttonCancel: '',
        buttonOk: '',
        content: '',
        title: ''
    };

    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogInterface
    ) {
        this.dialogObject = data;
    }

    ngOnInit() {}

    public okBtn(): void {
        this.dialogRef.close(true);
    }

    public cancelBtn(): void {
        this.dialogRef.close(false);
    }

    public okBtnIsVisible(): boolean {
        if (this.dialogObject.buttonOk.length > 0) {
            return true;
        }
        return false;
    }

    public cancelBtnIsVisible(): boolean {
        if (this.dialogObject.buttonCancel.length > 0) {
            return true;
        }
        return false;
    }
}
