<div>
    <p class="header-title">
        Su sesión expiró
    </p>
    <p class="small-label">
        Se cerró su sesión por inactividad
    </p>
    <div class="dialog-actions">
        <button mat-raised-button mat-dialog-close>
            Cerrar
        </button>
        <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    </div>
</div>