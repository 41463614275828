import { Component, OnInit, ViewChild, Renderer2, ElementRef, Output, EventEmitter, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-code-smsinput',
    templateUrl: './code-smsinput.component.html',
    styleUrls: ['./code-smsinput.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CodeSMSInputComponent),
            multi: true
        }
    ]
})
export class CodeSMSInputComponent implements OnInit, ControlValueAccessor {
    @ViewChild('firstDigit') firstDigit: ElementRef<
        HTMLInputElement
    >;
    @ViewChild('secondDigit') secondDigit: ElementRef<
        HTMLInputElement
    >;
    @ViewChild('thirdDigit') thirdDigit: ElementRef<
        HTMLInputElement
    >;
    @ViewChild('fourthDigit') fourthDigit: ElementRef<
        HTMLInputElement
    >;
    @ViewChild('fifthDigit') fifthDigit: ElementRef<
        HTMLInputElement
    >;
    @ViewChild('sixthDigit') sixthDigit: ElementRef<
        HTMLInputElement
    >;
    @Input() stringSize = 6;
    @Output() completed = new EventEmitter<boolean>();
    @Output() pin_number = new EventEmitter<string>();

    values = new Array<string>(this.stringSize);

    value: string;
    isDisabled: boolean;
    constructor(private renderer: Renderer2) {}

    ngOnInit() {
        this.resetForm();
    }

    resetForm() {
        this.values = ['', '', '', '', '', '', ''];
        this.firstDigit.nativeElement.value = '';
        this.secondDigit.nativeElement.value = '';
        this.thirdDigit.nativeElement.value = '';
        this.fourthDigit.nativeElement.value = '';
        this.fifthDigit.nativeElement.value = '';
        this.sixthDigit.nativeElement.value = '';
        this.value = '';
        this.onTouch();
        this.onChange(this.value);
    }

    setValues(
        $event: KeyboardEvent,
        targetEl: ElementRef<HTMLInputElement>,
        focusEl: ElementRef<HTMLInputElement>,
        previousEl: ElementRef<HTMLInputElement>,
        index: number
    ) {
        if ($event.which > 47 && $event.which < 58) {
            const currentElement = this.renderer.selectRootElement(
                targetEl
            ) as ElementRef<HTMLInputElement>;

            currentElement.nativeElement.value = $event.key;

            this.values[index] = $event.key;
            this.array2Value();
            if (focusEl !== null) {
                const nextElement = this.renderer.selectRootElement(
                    focusEl
                ) as ElementRef<HTMLInputElement>;
                setTimeout(() => {
                    nextElement.nativeElement.focus();
                }, 10);
            }
        } else if ($event.which === 8) {
            const currentElement = this.renderer.selectRootElement(
                targetEl
            ) as ElementRef<HTMLInputElement>;

            currentElement.nativeElement.value = '';

            this.values[index] = '';
            this.array2Value();
            this.setValueInputs();
            if (previousEl !== null) {
                setTimeout(() => {
                    const previousElement = this.renderer.selectRootElement(
                        previousEl
                    ) as ElementRef<HTMLInputElement>;
                    previousElement.nativeElement.focus();
                }, 20);
            }
        } else {
            if ($event.which !== 17 && $event.which !== 86) {
                $event.preventDefault();
            }
        }
    }

    array2Value() {
        this.value = '';
        this.values.forEach((v) => {
            this.value += v;
        });
        this.onTouch();
        this.onChange(this.value);
    }

    setValueInputs() {
        setTimeout(() => {
            this.firstDigit.nativeElement.value = this.values[0];
            this.secondDigit.nativeElement.value = this.values[1];
            this.thirdDigit.nativeElement.value = this.values[2];
            this.fourthDigit.nativeElement.value = this.values[3];
            this.fifthDigit.nativeElement.value = this.values[4];
            this.sixthDigit.nativeElement.value = this.values[5];
        }, 20);
    }

    onPaste($event: ClipboardEvent) {
        const textPasted = $event.clipboardData.getData('text');
        this.values = textPasted.split('');
        this.values = this.values.slice(0, 6);
        this.array2Value();
        this.setValueInputs();
        this.sixthDigit.nativeElement.focus();
    }

    firstDigitFn($event: KeyboardEvent) {
        if (this.stringSize <= 1) {
            this.sixthDigit = null;
        }
        this.setValues($event, this.firstDigit, this.secondDigit, null, 0);
        if (this.value.length === 4 || this.value.length === 6) {
            this.pinValidation();
        }
    }

    secondDigitFn($event) {
        if (this.stringSize <= 2) {
            this.sixthDigit = null;
        }
        this.setValues(
            $event,
            this.secondDigit,
            this.thirdDigit,
            this.firstDigit,
            1
        );
        if (this.value.length === 4 || this.value.length === 6) {
            this.pinValidation();
        }
    }

    thirdDigitFn($event) {
        if (this.stringSize <= 3) {
            this.sixthDigit = null;
        }
        this.setValues(
            $event,
            this.thirdDigit,
            this.fourthDigit,
            this.secondDigit,
            2
        );
        if (this.value.length === 4 || this.value.length === 6) {
            this.pinValidation();
        }
    }

    fourthDigitFn($event) {
        if (this.stringSize <= 4) {
            this.fifthDigit = null;
        }
        this.setValues(
            $event,
            this.fourthDigit,
            this.fifthDigit,
            this.thirdDigit,
            3
        );
        if (this.value.length === 4 || this.value.length === 6) {
            this.pinValidation();
        }
    }

    fifthDigitFn($event) {
        if (this.stringSize <= 5) {
            this.sixthDigit = null;
        }
        this.setValues(
            $event,
            this.fifthDigit,
            this.sixthDigit,
            this.fourthDigit,
            4
        );
        if (this.value.length === 6) {
            this.pinValidation();
        }
    }

    sixthDigitFn($event) {
        if (this.stringSize <= 6) {
        }
        this.setValues($event, this.sixthDigit, null, this.fifthDigit, 5);

        if (this.value.length === 6) {
            this.pinValidation();
        }
    }

    onChange(_: any) {}

    onTouch() {}

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.isDisabled = isDisabled;
    }

    writeValue(value: string): void {
        this.value = value;
    }

    pinValidation() {
        this.pin_number.emit(this.value);
    }
}
