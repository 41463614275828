import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-session-expired',
    templateUrl: './session-expired.component.html',
    styleUrls: ['./session-expired.component.scss']
})
export class SessionExpiredComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<SessionExpiredComponent>) {}

    ngOnInit() {}
}
