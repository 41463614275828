import { Component, OnInit, ElementRef, ViewChild, TemplateRef } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../auth.service';

@Component({
    selector: 'app-signout-notification',
    templateUrl: './signout-notification.component.html',
    styleUrls: ['./signout-notification.component.scss']
})
export class SignoutNotificationComponent implements OnInit {
    dialogRef: MatDialogRef<ElementRef>;
    @ViewChild('reporteConfirmacion', { static: true }) reporteConfirmacion: TemplateRef<any>;
    constructor(
        public dashboard: DashboardService,
        private dialog: MatDialog,
        public authService: AuthService
    ) {}

    ngOnInit() {
        this.logOut();
    }

    openDialog(): void {
        this.dialogRef = this.dialog.open(this.reporteConfirmacion, {
            width: '400px'
        });
    }
    logOut() {
        this.openDialog();
        this.authService.logOut();
    }
}
