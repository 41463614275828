import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-payment-info-request-view',
    templateUrl: './payment-info-request-view.component.html',
    styleUrls: ['./payment-info-request-view.component.scss']
})
export class PaymentInfoRequestViewComponent implements OnInit {

    @Output() userContinue = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit(): void {}

    userCanContinue(): void {
        this.userContinue.emit(true);
    }
}
