import { Directive, ElementRef, HostListener, EventEmitter, Output } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({ selector: '[appUpperCase]' })
export class UpperCaseDirective {
    constructor(private el: ElementRef, private control: NgControl) {}

    @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
    value: any;
    @HostListener('input', ['$event']) oninput($event) {
        const upper = this.el.nativeElement.value.toUpperCase();
        this.control.control.setValue(upper);
    }
}
