<form class="mt-3" [formGroup]="signUpForm" [hidden]="waitingActivation">
    <div *ngIf="signUpForm.hasError('existingAccount')"
         class="alert alert-danger d-flex justify-content-center align-items-center p-2 my-3">
        <img src="../../../assets/img/error-circle-100.svg"
             width="32"
             height="32"
             alt="Account Error">
        <p class="text-danger m-0">La cuenta ya esta registrada.</p>
        <!--   RESTABLECER BTN     -->
    </div>
    <div class="cx-container">
        <p class="x-small-label text-uppercase spaced-label pt-3">
            Teléfono
        </p>
        <app-auth-input controlName="account"
                        iconColor="#cf1520"
                        placeholder="Ingresa tu teléfono">
        </app-auth-input>
        <p class="x-small-label text-uppercase spaced-label pt-3">Contraseña</p>
        <mat-form-field class="w-100 pb-3"
                        floatLabel="never">
            <span matPrefix class="signup-input-icon material-symbols-rounded ms-2">
                lock
            </span>
            <div class="d-flex">
                <input matInput
                       [type]="hidePass ? 'password' : 'text'"
                       id="input-password"
                       #passwordInput
                       autocomplete="new-password"
                       placeholder="Contraseña"
                       formControlName="password"
                       (keydown.enter)="register()"/>
                <button matSuffix mat-icon-button
                        class="bg-transparent"
                        (click)="hidePass = !hidePass">
                        <span class="material-symbols-rounded accent-red-color">
                            {{ hidePass ? 'visibility_off' : 'visibility' }}
                        </span>
                </button>
            </div>
        </mat-form-field>
        <div class="d-flex align-items-center gap-3" *ngIf="signUpForm.get('password').hasError('minlength')">
            <img src="../../../assets/img/error-circle-100.svg"
                 width="32"
                 height="32"
                 alt="Account Error">
            <p class="text-danger m-0">Comprueba que la contraseña tenga 8 caracteres o más.</p>
        </div>
        <div class="d-flex align-items-center gap-3" *ngIf="signUpForm.get('password').touched && signUpForm.get('password').hasError('minlength')">
            <img src="../../../assets/img/error-circle-100.svg"
                 width="32"
                 height="32"
                 alt="Account Error">
            <p class="text-danger m-0">Escribe una contraseña segura.</p>
        </div>
        <p class="x-small-label text-uppercase spaced-label pt-3">Confirmar contraseña</p>
        <mat-form-field class="w-100 pb-3"
                        floatLabel="never"
                        [ngStyle]="{ width: channel === 'email' ? '100%' : '65%' }">
            <span matPrefix class="signup-input-icon material-symbols-rounded ms-2">
                lock
            </span>
            <div class="d-flex">
                <input matInput
                       [type]="hidePass ? 'password' : 'text'"
                       [errorStateMatcher]="matcher"
                       type="password"
                       autocomplete="new-password"
                       id="input-pass-confirmation"
                       placeholder="Confirma tú contraseña"
                       formControlName="passwordConfirmation"
                       (keydown)="moveFocus($event)"/>
                <button matSuffix mat-icon-button
                        class="bg-transparent"
                        (click)="hidePass = !hidePass">
                        <span class="material-symbols-rounded accent-red-color">
                            {{ hidePass ? 'visibility_off' : 'visibility' }}
                        </span>
                </button>
            </div>
        </mat-form-field>
        <div class="d-flex align-items-center gap-3" *ngIf="signUpForm.get('passwordConfirmation').touched && signUpForm.hasError('passwordDoCheck')">
            <img src="../../../assets/img/error-circle-100.svg"
                 width="32"
                 height="32"
                 alt="Account Error">
            <p class="text-danger m-0">Confirma tu contraseña.</p>
        </div>
        <div class="d-flex align-items-center gap-3"
             *ngIf="signUpForm.hasError('passwordDoCheck') && signUpForm.get('passwordConfirmation').touched">
            <img src="../../../assets/img/error-circle-100.svg"
                 width="32"
                 height="32"
                 alt="Account Error">
            <p class="text-danger m-0">Las contraseñas no coinciden.</p>
        </div>
        <!-- Validation code option section -->
        <p class="x-small-label my-3">
            Al crear esta cuenta de usuario comprendo que he leído y acepto los términos y condiciones establecidas por Caliwallet.
        </p>
        <button mat-flat-button
                [ngStyle]="{ 'background-color': '#D9282F', 'color': 'white' }"
                type="button"
                (click)="register()"
                class="full-width">
            Crear cuenta
        </button>
    </div>
</form>

<ng-template #confirmationCode>
    <div class="p-3">
        <div>
            <p class="app-module-subtitle">
                Recibiras un código de confirmación via WhatsApp:
            </p>
        </div>
        <div class="multiple-separation-bottom-actions">
            <button mat-flat-button class="role-action-btn flex-grow-1"
                    (click)="dismissNotifications()">
                Continuar
            </button>
            <button mat-button
                    (click)="changeCodeChannelToSMS()">
                Enviar por SMS
            </button>
        </div>
    </div>
</ng-template>

<ng-template #errorMessageTempl>
    <div class="p-3">
        <div>
            <p class="app-module-subtitle"></p>
            <p class="app-module-subtitle">
                {{ errorMessage }}
            </p>
        </div>
        <div class="multiple-separation-bottom-actions">
            <button mat-flat-button class="link-action-btn flex-grow-1"
                    (click)="dismissNotifications()">
                Aceptar
            </button>
        </div>
    </div>
</ng-template>

<app-sms-verification (userActivation)="getTabIndex($event)"
                      #CodeVerification>
</app-sms-verification>