import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weight'
})
export class WeightPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    const str: string = value.toString();
    const length = str.length;
    let result = '';

    // 1.000 > length = 5 (max: 3 decimals)
    (length > 5)
      ? result = str.substr(0, 5)
      : result = str;

    return result;
  }
}
