import {Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';

@Directive({
    selector: '[appCustomIconColor]'
})
export class CustomIconColorDirective implements OnChanges {
    static counter = 0;

    @Input() appCustomIconColor;
    styleEl: HTMLStyleElement = document.createElement('style');

    uniqueAttr = `app-custom-icon-color-${CustomIconColorDirective.counter++}`;

    private nativeEl: HTMLElement;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
    ) {
        this.nativeEl = this.el.nativeElement;
        // this.nativeEl.setAttribute(this.uniqueAttr, '');
        // this.nativeEl.appendChild(this.styleEl);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateColor();
    }

    updateColor(): void {
        // update dynamic style with the uniqueAttr
        this.renderer.setStyle(
            this.nativeEl,
            'background',
            this.appCustomIconColor
        );
    }
}
