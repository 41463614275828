import { Directive, Input, Output, HostListener, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({
    selector: '[appSubmitForm]'
})
export class SubmitFormDirective {
    @Input('appSubmitForm') formRef: FormGroup;
    @Output() valid = new EventEmitter<any>();
    constructor() {}

    @HostListener('click')
    handleClick() {
        this.markFieldsAsDirty();
        this.emitIfValid();
    }

    private markFieldsAsDirty() {
        Object.keys(this.formRef.controls).forEach(fieldName =>
            this.formRef.controls[fieldName].markAsDirty()
        );
    }

    private emitIfValid() {
        if (this.formRef.valid) {
            this.valid.emit();
        }
    }
}
