import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(value: string): string {
    let result = '';

    if (value) {
      const withoutDigits = value.replace(/[0-9]/g, '');
      const clearedValue = withoutDigits.replace(' ', '');
      const letter = clearedValue.substring(0, 1);
      const remainingWord = clearedValue.substring(1, value.length - 1).toLowerCase();

      result = `${letter}${remainingWord}`;
    }

    return result;
  }

}
