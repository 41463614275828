import {Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';
import {DomSanitizer} from '@angular/platform-browser';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import {mobile} from '../../consts/utilities';

@Component({
  selector: 'app-ecommerce-pdp-view',
  templateUrl: './ecommerce-pdp-view.component.html',
  styleUrls: ['./ecommerce-pdp-view.component.scss']
})
export class EcommercePdpViewComponent implements OnInit {
    private base_url = 'https://infocode.io/b2b';

    @Input() barcode: string | number;

    @Input() pdpImagesLink: any;
    @Input() pdpDetailsLink: any;
    @Input() pdpExtraDataLink: any;

    private readonly token: string;

    private isMobile: boolean;

    constructor(
        private sanitization: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(MAT_BOTTOM_SHEET_DATA) public bottom_data: any,
    ) {
        this.isMobile = mobile();
        this.token = `${environment.infocodeToken}`;
    }

    ngOnInit(): void {
        let value = '';

        if (this.isMobile) {
            const { barcode } = this.bottom_data;
            value = barcode;
        }

        if (!this.isMobile) {
            const { barcode } = this.data;
            value = barcode;
        }

        if (!this.barcode) {
            this.barcode = value;
        }

        this.pdpImagesLink = this.sanitizedResourceUrl('gallery');
        this.pdpDetailsLink = this.sanitizedResourceUrl('details');
        this.pdpExtraDataLink = this.sanitizedResourceUrl('extra-info');
    }

    private sanitizedResourceUrl(endpoint: string): any {
        return this.sanitization.bypassSecurityTrustResourceUrl(
            `${this.base_url}/${this.barcode}/${endpoint}?token=${this.token}`
        );
    }

}
