import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { AppUtils } from 'src/app/app-utils';
import { IconType } from '../dashboard-banner/dashboard-banner.component';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-responsive-input-nfr',
  templateUrl: './responsive-text-input-no-frm.component.html',
  styleUrls: ['./responsive-text-input-no-frm.component.scss']
})
export class ResponsiveTextInputNoFrmComponent extends FormControl implements OnInit {

  // Directives
  @Input() id: string;
  @Input() controlName: FormControl;
  @Input() type: string;
  @Input() mask = '';
  @Input() placeholder: string;
  @Input() showIcon = false;
  @Input() enableLabel = false;
  @Input() labelText = 'New Input';
  @Input() icon: string;
  @Input() iconType: IconType = IconType.MATERIAL_SYMBOL;
  @Input() enableEnterEvent: boolean;
  @Input() viewElementRef: ElementRef<any>;

  // Events
  @Output() enter = new EventEmitter<boolean>();

  constructor(
    private renderer: Renderer2,
  ) {
      super('', [] , null);
  }

  ngOnInit() {}

  onFocusedEvent(): void {
    if (AppUtils.mobile()) {
      this.renderer.addClass(this.viewElementRef.nativeElement, 'fit-height');
    }
  }

  onFocusedOutEvent(): void {
    if (AppUtils.mobile()) {
      this.renderer.removeClass(this.viewElementRef.nativeElement, 'fit-height');
    }
  }

  onEnterEvent(): void {
    this.enter.emit();
  }

}
