<div>
    <div class="pill-drag-indicator"></div>
    <div class="pdp-container">
        <div class="pdp-header">
            <iframe [src]="pdpImagesLink"
                width="100%"
                height="200px">
            </iframe>
        </div>
        <div class="pdp-content">
            <iframe [src]="pdpDetailsLink"
                    width="100%"
                    height="600px">
            </iframe>
        </div>
        <div class="pdp-bottom"></div>
    </div>
</div>
